import QueryInterface from '@/core/application/query/QueryInterface'

export default class GetHashesQuery implements QueryInterface
{
    private registryId: number;
    private recordId: number;
    private certificate: string;

    getClassName(): string
    {
        return 'GetHashesQuery';
    }

    getRegistryId(): number
    {
        return this.registryId;
    }

    getRecordId(): number
    {
        return this.recordId;
    }

    getCertificate(): string
    {
        return this.certificate;
    }
}